import React from 'react'

import { Container } from 'semantic-ui-react'
import Layout from '../components/Layouts/default'

export default function GioiThieu() {
  return (
    <Layout>
      <Container text style={{ marginTop: 20 }}>
        <h1>Giới thiệu về Savor</h1>

        <p>
          Savor là chuỗi cửa hàng bán đồ ăn nhanh tiện lợi được thành lập từ năm
          2018, bán chủ yếu là bánh mì, xôi với 5 cửa hàng trong nội thành Hà
          Nội. Sắp tới, Savor tiếp tục đẩy mạnh món trà sữa để tìm chỗ đứng vững
          chãi trong làng F&B.{' '}
        </p>

        <p>
          Với môi trường làm việc trẻ trung, năng động Savor đã, đang và sẽ
          không ngừng cung cấp những sản phẩm chất lượng cao đi kèm với dịch vụ
          hoàn hảo để mang lại sự trải nghiệm tốt nhất cho khách hàng.{' '}
        </p>

        <p>
          Với nguồn nhân lực trẻ và đầy nhiệt huyết, chúng tôi tin tưởng sẽ mang
          tới một môi trường làm việc thoải mái, được lắng nghe và tôn trọng ý
          kiến cá nhân, giờ trực linh hoạt không gò bó là cơ hội tốt để bạn cống
          hiến và phát huy giá trị cốt lõi của bản thân.
        </p>

        <p>
          Savor luôn chào đón những bạn trẻ với nhiệt huyết và khát khao tạo nên
          giá trị đích thực cho chính bản thân và cho khách hàng. Vì không ai
          trong chúng ta làm tốt bằng tất cả chúng ta cùng hợp sức!
        </p>
      </Container>
    </Layout>
  )
}
